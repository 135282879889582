import React from 'react'
import Logo from '../parts/logo'
import {Link} from 'react-router-dom'
import LeaderboardTable from '../common/leaderboardTable';
import { useAuth } from "../../contexts/AuthContext";


export default function Index() {
  const { isLoggedIn , user} = useAuth();
  return (
    <>
      <section className="home-section p-4">
        <div className="row w-100">
          {/* Left Side */}
          <div className="col-5">
            <div className="row h-100">
              <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                <Logo />
              </div>
              <div className="col-12 col-lg-6">
                <div className="row w-100 h-100">
                  <div className="col-12">
                    <div className="h-100 home-btns-div">
                      <Link to={`${!isLoggedIn ? "/login" : "/leaderboard"}`}>
                        <div
                          className="home-card"
                          // style={{ backgroundImage: `url(${leaderboard_bg})` }}
                        >
                          <h1>{isLoggedIn ? "Welcome" : "Login"}</h1>
                          {isLoggedIn && <p>{user?.name}</p>}
                        </div>
                      </Link>

                      <Link to="/movies">
                        <div
                          className="home-card"
                          // style={{ backgroundImage: `url(${movies_bg})` }}
                        >
                          <h1>Play</h1>
                        </div>
                      </Link>

                      <Link to="/yes-or-no">
                        <div
                          className="home-card"
                        >
                          <h1>Watched ?</h1>
                        </div>
                      </Link>

                      <Link to="/audience">
                        <div
                          className="home-card"
                          // style={{ backgroundImage: `url(${tv_bg})` }}
                        >
                          <h1>Audience</h1>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Leaderboard Side */}
          <div className="col-7 h-100">
            <section className="home-leaderboard-section">
              <LeaderboardTable isGlobal={true} />
            </section>
          </div>
        </div>
      </section>
    </>
  );
}


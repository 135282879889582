import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {useToggleWatchlistMovie} from "../../hooks/apis/movies";
import { useAuth } from "../../contexts/AuthContext";

export default function Index({
  movie,
  addYesMovie,
  addNoMovie,
  get_next_movie,
  setFilterOpen,
}) {
  const [animate, setAnimate] = React.useState(false);
  const [animateClass, setAnimateClass] = React.useState("");
  const { mutate: toggleWatchListMutate } = useToggleWatchlistMovie();
  const { isLoggedIn } = useAuth();

  const handleAnimation = (direction) => {
    setAnimateClass(direction);
    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
    }, 500);
  };

  const handleKeyDown = (event) => {
    if (!movie) return; // Disable hotkeys when there's no image

    if (event.key === "ArrowLeft") {
      // Left arrow for "No"
      addNoMovie(movie);
      handleAnimation("left");
    } else if (event.key === "ArrowRight") {
      // Right arrow for "Yes"
      addYesMovie(movie);
      handleAnimation("right");
    } else if (event.key === "ArrowUp") {
      // Up arrow for "Watchlist"
      handleAnimation("up");
      get_next_movie();
    } else if (event.key === "f") {
      setFilterOpen(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [movie]);

  return (
    <>
      <div className="poster-section">
        {movie ? (
          <img
            src={movie.image}
            className={`poster slide-${animate ? animateClass : ""}`}
            alt={movie?.title || "No Title"}
          />
        ) : (
          <Skeleton
            variant="rectangular"
            className="poster-skeleton"
            animation="wave"
            sx={{ bgcolor: "grey.900" }}
          />
        )}

        <div
          className="movie-bottom-info d-flex align-items-center justify-content-center yes-no-btns-section"
          style={{ padding: 0, height: "auto" }}
        >
          <button
            disabled={!movie}
            onClick={() => {
              addNoMovie(movie);
              handleAnimation("left");
            }}
            className="yes-no-btn no-btn"
            style={{ width: "40%" }}
          >
            <ArrowBackIcon /> No
          </button>

          {isLoggedIn && (
            <button
              disabled={!movie}
              onClick={() => {
                handleAnimation("up");
                get_next_movie();
                toggleWatchListMutate({
                  movie_id: movie?.movie_id,
                });
              }}
              className="yes-no-btn watch-btn"
              style={{ width: "20%" }}
            >
              +<br />
              Watchlist
            </button>
          )}

          <button
            disabled={!movie}
            onClick={() => {
              addYesMovie(movie);
              handleAnimation("right");
            }}
            className="yes-no-btn yes-btn"
            style={{ width: "40%" }}
          >
            Yes <ArrowForwardIcon />
          </button>
        </div>
      </div>
    </>
  );
}
